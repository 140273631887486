@tailwind base;
@tailwind components;
@tailwind utilities;

.dialog {
  box-shadow: 0 8px 6px -6px black;
  position: absolute;
  /* top: 50%;
  left: 0;
  right: 0; */
}

.image {
  width: 300px;
}

.round-checkbox {
  position: relative;
}

.round-checkbox label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.round-checkbox label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.round-checkbox input[type="checkbox"] {
  visibility: hidden;
}

.round-checkbox input[type="checkbox"]:checked + label {
  background-color: #66bb6a;
  border-color: #66bb6a;
}

.round-checkbox input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

input:focus {
  box-shadow: unset !important;
}
